import React, { CSSProperties, ReactNode } from 'react';
import { chakra } from '@chakra-ui/react';
import { Link } from 'gatsby';
import { withPrefix } from 'gatsby-link';
import { addQueryParams, makeConsistentURL } from '../../services/URL';
import './Link.scss';

/**
 * Links that start with these URls open in a new tab
 */
const externalLinks = [
  '/api',
  '/samples',
  '/samples/web',
  '/webviewer/demo',
  '/benchmarks',
  'dev.apryse.com',
  'showcase.apryse.com',
  'https://apryse.com/'
];

type LinkProps = {
  sendEvent?: boolean;
  blue?: boolean;
  indigo?: boolean;
  className?: string;
  style?: CSSProperties;
  to: string;
  active?: boolean;
  onClick?: (E?: React.MouseEvent<HTMLElement>) => void;
  children: ReactNode;
  forceHighlight?: boolean;
  newTab?: boolean;
  hideMissing?: boolean;
  noHover?: boolean;
  rightIcon?: ReactNode;
  ariaLabel?: string;
}

/** 
 * Standard link component
 * By default, all outbound links open in a new tab
 */
const LinkWrapper = ({
  sendEvent,
  blue,
  indigo,
  className = '',
  style = {},
  to,
  active,
  onClick = () => undefined,
  children,
  forceHighlight = false,
  newTab,
  hideMissing,
  noHover,
  rightIcon,
  ariaLabel
}: LinkProps) => {

  let finalOnClick = null;

  if (sendEvent && to) {
    finalOnClick = () => {      
      if (onClick) {
        onClick();
      }
    }
  } else {
    finalOnClick = onClick;
  }

  if(noHover) {
    className += ' noHover';
  }

  if (!to || to === '') {
    if (hideMissing) return null;
    return <span className={`Link ${className} ${forceHighlight ? '' : 'NoLink'} ${blue ? 'blue' : ''} ${indigo ? 'indigo' : ''}`} style={style} onClick={finalOnClick} tabIndex={0} role='link' aria-label={ariaLabel}>{children}</span>;
  }

  let externalRel = 'noopener noreferrer';

  //nofollow on any external links + older (numbered) versions of the api docs
  if( (!to.includes('apryse.com') && to.includes('http')) || (to.includes('/api/') && /\d/.test(to))) {
    externalRel += ' nofollow';
  }

  // Discord links should just be nofollow
  if(to.includes('apryse.com/discord')) {
    externalRel = 'nofollow';
  }

  if (to.startsWith('#')) {
    // eslint-disable-next-line
    return <a href={to} className={`Link ${blue ? 'blue' : ''} ${indigo ? 'indigo' : ''} ${className}`} style={style} onClick={finalOnClick} aria-label={ariaLabel}>{children}</a>;
  }
  
  to = makeConsistentURL(to);

  if (externalLinks.some(l => to.startsWith(l))) {
    return <a className={`Link ${className}`} style={style} href={to} target="_blank" rel={externalRel} onClick={() => { finalOnClick() }} aria-label={ariaLabel}>{children}</a>;
  }
  
  let finalURL = to;
  
  if (finalURL.indexOf('http') === -1 && finalURL.indexOf('mailto') === -1) {
    finalURL = addQueryParams(finalURL);
    finalURL = withPrefix(finalURL);
  }

  if (finalURL.indexOf('http') === 0 || finalURL.indexOf('mailto:') === 0 || newTab) {
    return (
      <a className={`Link ${className} ${blue ? 'blue' : ''}`} style={style} href={finalURL} target='_blank' rel={externalRel} onClick={() => { finalOnClick() }} aria-label={ariaLabel}>
        {children}
      </a>
    );  
  }

  return (
    <Link className={`Link ${className} ${active ? 'active' : ''} ${blue ? 'blue' : ''} ${indigo ? 'indigo' : ''}`} style={style} to={finalURL} onClick={finalOnClick} aria-label={ariaLabel}>
      {children} {rightIcon}
    </Link>
  );
}

export default chakra(LinkWrapper);